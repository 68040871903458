// Material Design Icons
$mdi-font-path: '~@mdi/font/fonts';

// Lato Font
$lato-font-path: '~lato-font/fonts';

// Colors
$black: #000000;
$white: #FFFFFF;

// Brand Colors
$linkedin-color: #0077B5;
$facebook-color: #3B5998;
$twitter-color: #1DA1F2;

// Font Variables
$font-family-sans-serif: 'Lato, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
$font-family-monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace';

// OpenDecide Variables
$navbar-height: 56px;
$sidebar-width: 250px;
$mobile-appbar-height: 56px;
$main-content-padding-top: 1em;

// Material Design Theme
@use '@angular/material' as mat;
@import 'material-theme';

// OpenDecide Variables
$mediators: (
    red: mat.get-color-from-palette(mat.$purple-palette, 500), // Directive
    orange: mat.get-color-from-palette(mat.$indigo-palette, 700), // Participative
    opale: mat.get-color-from-palette(mat.$light-blue-palette, 700), // Autonomous
);
