@import 'lato-font/scss/internal/mixins';

// Override mixin to add font-display option
@mixin lato-font-face($variant, $weight, $style, $font-name) {
    font-display: swap;

    // Override lato-font-face mixin
    $variant: 'lato-' + $variant;

    @if (italic == $style) {
        $variant: $variant + '-italic';
    }

    font-family: $font-name;
    font-weight: $weight;
    font-style: $style;
    text-rendering: optimizeLegibility;
    src: url('#{$lato-font-path}/#{$variant}/#{$variant}.woff2') format('woff2'),
    url('#{$lato-font-path}/#{$variant}/#{$variant}.woff') format('woff');
}
